import React from 'react';
import {ItemProps, TableComponents, TableVirtuoso} from 'react-virtuoso';

import {Box} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {TraceSession} from '@wildix/wim-voicebots-client';

import formatDateTime from '../../helpers/formatDateTime';

interface ColumnData {
  dataKey: keyof TraceSession;
  label: string;
  width?: number;
}

interface TableContext {
  openDetails: (item: TraceSession) => void;
}

const columns: ColumnData[] = [
  {
    width: 130,
    label: 'Session ID',
    dataKey: 'sessionId',
  },
  {
    width: 100,
    label: 'Voice Bot',
    dataKey: 'botName',
  },
  {
    width: 160,
    label: 'Caller',
    dataKey: 'callerNumber',
  },
  {
    width: 60,
    label: 'Duration',
    dataKey: 'duration',
  },
  {
    width: 120,
    label: 'Date',
    dataKey: 'start',
  },
  {
    width: 80,
    label: 'Language',
    dataKey: 'language',
  },
];

function TableRowCustom(props: ItemProps<TraceSession> & {context?: TableContext}) {
  const {context, item} = props;

  return <TableRow {...props} onClick={() => context?.openDetails(item)} />;
}

const VirtuosoTableComponents: TableComponents<TraceSession, TableContext> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer component={Paper} elevation={1} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed'}} />,
  TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow: TableRowCustom,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align="left"
          style={{width: column.width}}
          sx={{backgroundColor: 'background.paper'}}>
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContentValue(name: keyof TraceSession, value: undefined | string | number | Date) {
  if (value instanceof Date) {
    return formatDateTime(value);
  } else if (!value) {
    return '-';
  }

  return value;
}

function rowContent(_index: number, row: TraceSession) {
  return (
    <>
      {columns.map((column) => (
        <TableCell key={column.dataKey} align="left">
          {rowContentValue(column.dataKey, row[column.dataKey])}
        </TableCell>
      ))}
    </>
  );
}

export interface TracesTableContext {
  openDetails: (item: TraceSession) => void;
}

export interface TracesTableProps {
  traces: TraceSession[];
  isLoading: boolean;
  context: TracesTableContext;
}

export default function TracesTable(props: TracesTableProps) {
  const {traces, context, isLoading} = props;

  return (
    <Box sx={{height: '480px', width: '100%', mt: 4}}>
      <TableVirtuoso
        data={traces}
        context={context}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
      />
    </Box>
  );
}
