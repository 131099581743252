import React from 'react';

import {Box, styled} from '@mui/material';

import {TraceActivity} from '@wildix/wim-voicebots-client';

interface TraceActivityResultContentProps {
  activity: TraceActivity;
}

// TODO: Remove copy-paste.
const Title = styled(Box)({
  mt: 3,
  fontSize: '13px',
  fontWeight: '500',
});

const Value = styled(Box)({
  mt: 1,
  padding: '12px',
  borderRadius: '6px',
  backgroundColor: '#FAFAFA',
});

export default function TraceActivityResultContent(props: TraceActivityResultContentProps) {
  const {activity} = props;
  const {result} = activity;

  if (result?.text) {
    return (
      <Box>
        <Title>Result</Title>
        <Value>{result.text}</Value>
      </Box>
    );
  } else if (result?.error) {
    return (
      <Box>
        <Title>Error</Title>
        <Value>{result.error.message}</Value>
      </Box>
    );
  }

  return null;
}
