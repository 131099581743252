import React, {useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {Link, useNavigate} from 'react-router-dom';

import {Box, Breadcrumbs, Typography} from '@mui/material';

import {ListTracesCommand, TraceSession} from '@wildix/wim-voicebots-client';

import getWimVoiceBotsClient from '../../../base/helpers/getWimVoiceBotsClient';
import VoiceBotsIcon from '../../../base/icons/VoiceBotsIcon';
import {VOICEBOTS_TITLE} from '../../../texts';
import {VoiceBotsPath} from '../constants';
import useCompletedTracesQuery from '../hooks/useCompletedTracesQuery';
import useOngoingTracesQuery from '../hooks/useOngoingTracesQuery';
import TracesTable, {TracesTableContext} from './traces/TracesTable';
import TracesViewer from './traces/TracesViewer';

export default function VoiceBotsLogsScreen() {
  const navigate = useNavigate();

  const completedTracesQuery = useCompletedTracesQuery();
  const ongoingTracesQuery = useOngoingTracesQuery();

  const tracesQuery = useQuery('voicebots:traces', () => getWimVoiceBotsClient().send(new ListTracesCommand({})), {
    retry: false,
  });

  const [activeSession, setActiveSession] = useState<TraceSession | undefined>();

  // if (query.isError) {
  //   return <ApiErrorBox error={query.error} />;
  // }

  const context: TracesTableContext = {
    openDetails: (item: TraceSession) => {
      setActiveSession(item);
    },
  };

  const onTraceClose = () => {
    setActiveSession(undefined);
  };

  const traces = useMemo(
    () => [...(ongoingTracesQuery.data?.traces || []), ...(completedTracesQuery.data?.traces || [])],
    [ongoingTracesQuery.data, completedTracesQuery.data],
  );

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary" component={Link} to={VoiceBotsPath.base}>
          {VOICEBOTS_TITLE}
        </Typography>
        <Typography color="text.primary">Logs</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '1024px'}}>
        <Box width="96px">
          <VoiceBotsIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5} pb={8}>
          <Typography color="text.primary" variant="h5">
            Logs
          </Typography>

          <Box sx={{height: '480px', width: '100%', mt: 4}}>
            <TracesTable traces={traces} context={context} isLoading={tracesQuery.isLoading} />
          </Box>
        </Box>
      </Box>
      <TracesViewer session={activeSession} onClose={onTraceClose} />
    </Box>
  );
}
