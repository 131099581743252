import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box} from '@mui/material';

import {VoiceBotEmbeddedToolType, VoiceBotToolType} from '@wildix/wim-voicebots-client';

import {BotFormData} from '../../types';
import AddToolButton from './tools/AddToolButton';
import DelegateTool from './tools/DelegateTool';
import FunctionTool from './tools/FunctionTool';
import HangupTool from './tools/HangupTool';
import TransferTool from './tools/TransferTool';

interface BotLlmFunctionsFieldProps {
  form: UseFormReturn<BotFormData>;
}

export default function BotLlmFunctionsField(props: BotLlmFunctionsFieldProps) {
  const {form} = props;
  const {control, watch, formState, trigger, getValues, setValue} = form;
  const {errors, isSubmitting} = formState;
  const tools = watch('endpoint.llm.tools');
  const embeddedTools = watch('endpoint.llm.embeddedTools');

  const components: React.ReactNode[] = [];

  let num = 1;

  if (embeddedTools) {
    let toolsIndex = 0;

    for (const tool of embeddedTools) {
      if (tool.type === VoiceBotEmbeddedToolType.DELEGATE) {
        components.push(
          <DelegateTool
            form={form}
            path={`endpoint.llm.embeddedTools.${toolsIndex}`}
            index={toolsIndex}
            position={num++}
          />,
        );
      } else if (tool.type === VoiceBotEmbeddedToolType.HANGUP) {
        components.push(
          <HangupTool
            form={form}
            path={`endpoint.llm.embeddedTools.${toolsIndex}`}
            index={toolsIndex}
            position={num++}
          />,
        );
      } else if (tool.type === VoiceBotEmbeddedToolType.TRANSFER) {
        components.push(
          <TransferTool
            form={form}
            path={`endpoint.llm.embeddedTools.${toolsIndex}`}
            index={toolsIndex}
            position={num++}
          />,
        );
      }

      toolsIndex++;
    }
  }

  if (tools) {
    let toolsIndex = 0;

    for (const tool of tools) {
      components.push(
        <FunctionTool
          form={form}
          tool={tool}
          path={`endpoint.llm.tools.${toolsIndex}`}
          index={toolsIndex}
          position={num++}
        />,
      );
      toolsIndex++;
    }
  }

  const onAddToolClick = (type: string) => {
    if (type === 'hangup') {
      // TODO
      setValue('endpoint.llm.embeddedTools', [
        ...(embeddedTools || []),
        {
          type: VoiceBotEmbeddedToolType.HANGUP,
          name: 'hangup',
        },
      ]);
    } else if (type === 'transfer') {
      setValue('endpoint.llm.embeddedTools', [
        ...(embeddedTools || []),
        {
          type: VoiceBotEmbeddedToolType.TRANSFER,
          name: 'transfer',
        },
      ]);
    } else if (type === 'delegate') {
      setValue('endpoint.llm.embeddedTools', [
        ...(embeddedTools || []),
        {
          type: VoiceBotEmbeddedToolType.DELEGATE,
          name: 'delegate',
        },
      ]);
    } else if (type === 'webhook') {
      setValue('endpoint.llm.tools', [
        ...(tools || []),
        {
          type: VoiceBotToolType.FUNCTION,
        },
      ]);
    }
  };

  return (
    <div>
      <AddToolButton tools={tools} embeddedTools={embeddedTools} onClick={onAddToolClick} />
      <div>
        {components.map((node, index) => (
          <Box mb={2} key={index}>
            {node}
          </Box>
        ))}
      </div>
    </div>
  );
}
