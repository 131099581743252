import {useQuery} from 'react-query';

import {ListTracesCommand} from '@wildix/wim-voicebots-client';

import getWimVoiceBotsClient from '../../../base/helpers/getWimVoiceBotsClient';

export default function useCompletedTracesQuery() {
  const query = useQuery('vb:traces:completed', () => getWimVoiceBotsClient().send(new ListTracesCommand({})), {
    retry: false,
  });

  return query;
}
