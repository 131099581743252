import React from 'react';

import {Box, styled} from '@mui/material';

import {TraceActivity} from '@wildix/wim-voicebots-client';

interface TraceActivityContentProps {
  activity: TraceActivity;
}

const Title = styled(Box)({
  marginTop: '24px',
  fontSize: '13px',
  fontWeight: '500',
});
const Value = styled(Box)({
  marginTop: '8px',
  padding: '12px',
  borderRadius: '6px',
  backgroundColor: '#FAFAFA',
});

export default function TraceActivityInputContent(props: TraceActivityContentProps) {
  const {activity} = props;
  const {trigger} = activity;
  const components: React.ReactNode[] = [];

  if (trigger.replyEvent) {
    components.push(<Title key="reply_title">Input</Title>);
    components.push(<Value key="reply_value">{trigger.replyEvent.event.text}</Value>);
  } else if (trigger.interruptionEvent) {
    components.push(<Title key="interruption_played_title">Played Text</Title>);
    components.push(<Value key="interruption_played_value">{trigger.interruptionEvent.event.playedText}</Value>);
    components.push(<Title key="interruption_original_title">Original Text</Title>);
    components.push(<Value key="interruption_original_value">{trigger.interruptionEvent.event.originalText}</Value>);
  } else if (trigger.playbackEvent) {
    components.push(<Title key="playback_title">Text</Title>);
    components.push(<Value key="playback_value">{trigger.playbackEvent.event.text}</Value>);
  } else if (trigger.transcriptionEvent) {
    components.push(<Title key="transcription_title">Transcription</Title>);
    components.push(<Value key="transcription_value">{trigger.transcriptionEvent.event.text}</Value>);
  }

  if (components.length === 0) {
    return null;
  }

  return <Box>{components}</Box>;
}
