import React from 'react';

import {Box, Menu, MenuItem, Typography} from '@mui/material';
import Button from '@mui/material/Button';

import {VoiceBotEmbeddedTool, VoiceBotEmbeddedToolType, VoiceBotTool} from '@wildix/wim-voicebots-client';

import {find} from 'lodash';

interface AddToolButtonProps {
  tools: VoiceBotTool[] | undefined;
  embeddedTools: VoiceBotEmbeddedTool[] | undefined;
  onClick: (type: string) => void;
}

export default function AddToolButton(props: AddToolButtonProps) {
  const {tools, embeddedTools, onClick} = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isHangupAvailable = embeddedTools
    ? find(embeddedTools, {type: VoiceBotEmbeddedToolType.HANGUP}) === undefined
    : true;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        size="small"
        onClick={handleClick}>
        Add tool
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            onClick('transfer');
          }}>
          <Box>
            <div>Transfer</div>
            <Box>
              <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                Allows assistant to transfer the call to human or any other diaplan options.
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onClick('delegate');
          }}>
          <Box>
            <div>Delegate</div>
            <Box>
              <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                Allows the assistant to delegate the user's request to a specialized voice bot.
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        {isHangupAvailable && (
          <MenuItem
            onClick={() => {
              handleClose();
              onClick('hangup');
            }}>
            <Box>
              <div>Hangup</div>
              <Box>
                <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                  Allows assistant to hangup the call by himself if it is considered ended.
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
            onClick('webhook');
          }}>
          <Box>
            <div>Third-party Function</div>
            <Box>
              <Typography sx={{fontSize: '12px', color: '#757679', pt: 0.5}}>
                Allows to integrate our data and various API with assistant.
              </Typography>
            </Box>
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
}
