import React from 'react';

import {Box} from '@mui/material';
import Button from '@mui/material/Button';

import {TraceSegment} from '@wildix/wim-voicebots-client';

interface TraceActivityContentProps {
  segments: TraceSegment[];
  onClick: (segmentId: string) => void;
}

export default function TraceActivitySegmentsContent(props: TraceActivityContentProps) {
  const {segments, onClick} = props;

  if (segments.length === 0) {
    return null;
  }

  return (
    <Box>
      <Box sx={{mt: 3}}>
        <Box sx={{fontSize: '13px', fontWeight: '500'}}>Pipeline</Box>
      </Box>
      {segments.map((segment) => (
        <Box key={segment.id} sx={{mt: 1.5}}>
          <Button
            variant="text"
            onClick={() => onClick(segment.id)}
            sx={{mt: 1, padding: '12px', borderRadius: '6px', backgroundColor: '#1976d21f'}}>
            <Box sx={{fontSize: '14px', fontWeight: '500'}}>Generation ({segment.id})</Box>
          </Button>
        </Box>
      ))}
    </Box>
  );
}
