import React from 'react';

import {Box} from '@mui/material';

import {TraceActivity, TraceSegment} from '@wildix/wim-voicebots-client';

import formatActivityDescription from '../../helpers/formatActivityDescription';
import TraceActivityInputContent from './TraceActivityInputContent';
import TraceActivityResultContent from './TraceActivityResultContent';
import TraceActivitySegmentsContent from './TraceActivitySegmentsContent';

interface TraceActivityContentProps {
  activity: TraceActivity;
  segments: TraceSegment[];
  onSegmentClick: (segmentId: string) => void;
}

export default function TraceActivityContent(props: TraceActivityContentProps) {
  const {activity, segments, onSegmentClick} = props;
  const description = formatActivityDescription(activity);

  return (
    <Box sx={{flex: '1', padding: '8px 18px', overflow: 'auto'}}>
      <Box sx={{fontSize: '18px', fontWeight: '500'}}>{description.title}</Box>
      <TraceActivityInputContent activity={activity} />
      <TraceActivitySegmentsContent segments={segments} onClick={onSegmentClick} />
      <TraceActivityResultContent activity={activity} />
    </Box>
  );
}
