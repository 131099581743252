import {IconButton, Stack} from '@mui/material';
import Box from '@mui/material/Box';

import {TraceSegment} from '@wildix/wim-voicebots-client';

import BackIconWeb24 from '../../../../base/icons/BackIconWeb24';

interface TraceSegmentContentProps {
  segment: TraceSegment;
  onClose: () => void;
}

export default function TraceSegmentContent(props: TraceSegmentContentProps) {
  const {segment, onClose} = props;

  return (
    <Box sx={{flex: '1', padding: '8px 18px', overflow: 'auto'}}>
      <Stack flexDirection="row" alignItems="center">
        <IconButton sx={{marginLeft: '-12px'}} onClick={onClose}>
          <BackIconWeb24 />
        </IconButton>
        <Box sx={{flex: '1', fontSize: '18px', fontWeight: '500'}}>Segment</Box>
      </Stack>
      <Box sx={{marginTop: '24px', fontSize: '13px', fontWeight: '500'}}>Details</Box>
      <Box sx={{mt: 1.5, fontSize: '12px'}} component="pre">
        {JSON.stringify(segment, null, 2)}
      </Box>
    </Box>
  );
}
