import React, {useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {Box, Breadcrumbs, CircularProgress, Typography} from '@mui/material';
import Button from '@mui/material/Button';

import ApiErrorBox from '../../../base/components/error/ApiErrorBox';
import VoiceBotsIcon from '../../../base/icons/VoiceBotsIcon';
import {VOICEBOTS_DESCRIPTION, VOICEBOTS_TITLE} from '../../../texts';
import {VoiceBotsPath} from '../constants';
import useListVoiceBotsQuery from '../hooks/useListVoiceBotsQuery';
import BotListItem from './list/BotListItem';

export default function VoiceBotsScreen() {
  const navigate = useNavigate();
  const query = useListVoiceBotsQuery();

  const onAddClick = useCallback(() => {
    navigate(VoiceBotsPath.add);
  }, [navigate]);

  const onLogsClick = useCallback(() => {
    navigate(VoiceBotsPath.logs);
  }, [navigate]);

  if (query.isError) {
    return <ApiErrorBox error={query.error} />;
  }

  return (
    <Box sx={{p: 2, paddingTop: 0}}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="text.primary" component={Link} to="/">
          Hub
        </Typography>
        <Typography color="text.primary">{VOICEBOTS_TITLE}</Typography>
      </Breadcrumbs>
      <Box display="flex" sx={{mt: 4, width: '842px'}}>
        <Box width="96px">
          <VoiceBotsIcon width={96} height={96} />
        </Box>
        <Box flex={1} ml={5} pb={8}>
          <Typography color="text.primary" variant="h5">
            Voice Bots
          </Typography>
          <Typography color="text.primary" sx={{paddingTop: 2}}>
            {VOICEBOTS_DESCRIPTION}
          </Typography>
          <Button sx={{mt: 2}} onClick={onAddClick} disabled={query.isLoading} variant="contained" size="medium">
            Add new voicebot
          </Button>
          <Button sx={{mt: 2, ml: 1.5}} onClick={onLogsClick} variant="outlined" size="medium">
            Logs
          </Button>
          <Box mt={4}>
            {query.isLoading && <CircularProgress size={24} />}
            {query.isSuccess ? query.data.bots.map((bot) => <BotListItem key={bot.id} bot={bot} />) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
