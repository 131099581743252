import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box, IconButton, Stack, Typography} from '@mui/material';
import Button from '@mui/material/Button';

import {VoiceBotTool} from '@wildix/wim-voicebots-client';

import {isEmpty} from 'lodash';

import RemoveIcon20 from '../../../../../base/icons/RemoveIcon20';
import {BotFormData} from '../../../types';
import FunctionDescriptionField from './FunctionDescriptionField';
import FunctionIntegrationWebhookAuthorizationField from './FunctionIntegrationWebhookAuthorizationField';
import FunctionIntegrationWebhookHeadersField from './FunctionIntegrationWebhookHeadersField';
import FunctionIntegrationWebhookPipelineField from './FunctionIntegrationWebhookPipelineField';
import FunctionIntegrationWebhookUrlField from './FunctionIntegrationWebhookUrlField';
import FunctionNameField from './FunctionNameField';
import FunctionParametersField from './FunctionParametersField';

interface WebhookToolProps {
  position: number;
  index: number;
  tool: VoiceBotTool;
  form: UseFormReturn<BotFormData>;
  path: string;
}

export default function FunctionTool(props: WebhookToolProps) {
  const {position, index, form, path} = props;
  const {watch, setValue, formState} = form;
  const {isSubmitting} = formState;
  const tools = watch('endpoint.llm.tools');

  const onDeleteClick = () => {
    setValue(
      'endpoint.llm.tools',
      (tools || []).filter((item, itemIndex) => itemIndex !== index),
    );
  };

  const onAddAuthorizationClick = () => {
    setValue(`endpoint.llm.tools.${index}.function.integration.webhook.authorization`, {
      bearer: {
        token: '',
      },
    });
  };

  const onAddHeadersClick = () => {
    setValue(`endpoint.llm.tools.${index}.function.integration.webhook.headers`, [
      {
        key: '',
        value: '',
      },
    ]);
  };

  const toolData = tools ? tools[index] : undefined;

  const isAuthorizationAdded = !isEmpty(toolData?.function?.integration?.webhook?.authorization);
  const isHeadersAdded = !isEmpty(toolData?.function?.integration?.webhook?.headers);

  return (
    <Box sx={{pt: 1, pb: 3}}>
      <Stack flexDirection="row" alignItems="center">
        <Box>{position}.</Box>
        <Box ml={1}>Third-party Function</Box>
        <Box flex="1" sx={{height: '1px', background: '#CCC', ml: 2}} />
        <Box ml={1}>
          <IconButton sx={{width: '32px', height: '32px', padding: '6px'}} onClick={onDeleteClick}>
            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
        </Box>
      </Stack>
      <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
        Allows to integrate your data sources or APIs. This lets the voicebot make HTTP API calls to get data or start
        specific API requests when certain events happen during the conversation.
      </Typography>
      <Box pt={2} maxWidth="75%">
        <Box>
          <FunctionNameField form={form} path={`${path}.function.name`} />
          <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
            * Tool name is a short name of your tool. Example: <b>get_available_slots</b>
          </Typography>
        </Box>
        <Box mt={2}>
          <FunctionDescriptionField form={form} path={`${path}.function.description`} />
          <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
            * The description is essential for directing the voicebot on when to utilize your tool. Example:{' '}
            <b>Gets available timeslots for the appointment.</b>
          </Typography>
        </Box>
        <Box mt={2}>
          <FunctionParametersField form={form} path={`${path}.function.parameters`} />
          <Typography sx={{fontSize: '12px', color: '#757679', pt: 1}}>
            * The parameters the functions accepts, described as a JSON Schema object. See the{' '}
            <a href="https://platform.openai.com/docs/guides/function-calling" target="_blank" rel="noreferrer">
              guide
            </a>{' '}
            for examples, and the{' '}
            <a href="https://json-schema.org/understanding-json-schema" target="_blank" rel="noreferrer">
              JSON Schema reference
            </a>{' '}
            for documentation about the format.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography sx={{fontSize: '16px'}}>Integration</Typography>
          <Box mt={2}>
            <FunctionIntegrationWebhookUrlField form={form} path={`${path}.function.integration.webhook.url`} />
            {(!isAuthorizationAdded || !isHeadersAdded) && (
              <Box mt={0.5}>
                {!isAuthorizationAdded && (
                  <Button
                    variant="text"
                    sx={{fontSize: '10px'}}
                    size="small"
                    disabled={isSubmitting}
                    onClick={onAddAuthorizationClick}>
                    Add authorization
                  </Button>
                )}
                {!isHeadersAdded && (
                  <Button
                    variant="text"
                    sx={{fontSize: '10px', ml: isAuthorizationAdded ? 0 : 1}}
                    size="small"
                    disabled={isSubmitting}
                    onClick={onAddHeadersClick}>
                    Add headers
                  </Button>
                )}
              </Box>
            )}
            {isAuthorizationAdded && (
              <Box mt={isHeadersAdded ? 0.75 : 0}>
                <FunctionIntegrationWebhookAuthorizationField
                  form={form}
                  path={`${path}.function.integration.webhook.authorization`}
                />
              </Box>
            )}
            {isHeadersAdded && (
              <Box mt={isAuthorizationAdded ? 0.75 : 0}>
                <FunctionIntegrationWebhookHeadersField
                  form={form}
                  path={`${path}.function.integration.webhook.headers`}
                />
              </Box>
            )}
            <Box mt={2}>
              <FunctionIntegrationWebhookPipelineField form={form} path={`${path}.function.integration.webhook`} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
