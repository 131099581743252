import {TraceActivity} from '@wildix/wim-voicebots-client';

interface Description {
  title: string;
  color: string;
  content?: string;
}

export default function formatActivityDescription(activity: TraceActivity): Description {
  const {trigger} = activity;

  if (trigger.connectEvent) {
    return {title: 'Session Started', color: 'rgba(0,128,0,.9)'}; // Green
  } else if (trigger.disconnectEvent) {
    return {title: 'Session Ended', color: 'rgba(255,0,0,.9)'}; // Red
  } else if (trigger.replyEvent) {
    return {title: 'Reply', content: trigger.replyEvent.event.text, color: 'rgba(28,96,217,.9)'}; // Blue
  } else if (trigger.completeEvent) {
    return {title: 'Complete', color: 'rgba(128,128,128,.9)'}; // Gray
  } else if (trigger.interruptionEvent) {
    return {title: 'Interruption', color: 'rgba(255,255,0,.9)'}; // Yellow
  } else if (trigger.playbackEvent) {
    return {
      title: trigger.playbackEvent.event.complete ? 'Playback Ended' : 'Playback Started',
      content: trigger.playbackEvent.event.text,
      color: 'rgba(128,0,128,.9)',
    }; // Purple
  } else if (trigger.transcriptionEvent) {
    return {title: 'Transcription', content: trigger.transcriptionEvent.event.text, color: 'rgba(255,136,0,.8)'}; // Cyan
  } else if (trigger.sayCommand) {
    return {title: 'Say', content: trigger.sayCommand.command.text, color: 'rgba(0,0,255,.9)'}; // Light Blue
  } else if (trigger.hangupCommand) {
    return {title: 'Hangup', color: 'rgba(139,0,0,.9)'}; // Dark Red
  } else if (trigger.transferCommand) {
    return {title: 'Transfer', color: 'rgba(255,136,0,.8)'}; // Orange
  } else if (trigger.silenceTimeoutTask) {
    return {title: 'Silence Detection', color: 'rgba(165,42,42,.9)'}; // Brown
  } else if (trigger.durationTimeoutTask) {
    return {title: 'Max Duration Detection', color: 'rgba(64,64,64,.9)'}; // Dark Gray
  }

  return {title: 'Unknown', color: 'rgba(0,0,0,.9)'}; // Black
}
