import React from 'react';

import {Box, CircularProgress, styled} from '@mui/material';
import Button from '@mui/material/Button';

import {TraceActivity, TraceActivityStatus} from '@wildix/wim-voicebots-client';

import formatActivityDescription from '../../helpers/formatActivityDescription';
import formatDurationFromMillis from '../../helpers/formatDurationFromMillis';

interface TraceTimelineActivityProps {
  activity: TraceActivity;
  selected: boolean;
  onClick: () => void;
}

//
// const BackLink = styled(Link, {shouldForwardProp: (propName) => propName !== 'isKite'})<BackLinkProps>(
//   ({theme, isKite}) => ({
//     display: isKite ? 'flex' : 'none',
//     width: 36,
//     height: 36,
//     alignItems: 'center',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'flex',
//     },
//   }),
// );

const ActivityButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  padding: '4px 18px',
  textDecoration: 'none',
  textTransform: 'none',
  fontWeight: '400',
  flex: '1',
  overflow: 'hidden',
  width: '100%',
  /* justify-content: center; */
  textAlign: 'left',
  borderRadius: 0,
  '&[disabled]': {
    backgroundColor: 'rgba(22, 76, 116, 0.08)',
    // fontWeight: '600',
  },
});

export default function TraceTimelineActivity(props: TraceTimelineActivityProps) {
  const {activity, selected, onClick} = props;
  const {duration, start, status} = activity;
  const {title, content, color} = formatActivityDescription(activity);

  // TODO: Display error and cancelled cases.
  // {status === TraceActivityStatus.ERROR && <Box></Box>}
  // {status === TraceActivityStatus.CANCELLED && <CircularProgress color="primary" size={12} />}

  return (
    <ActivityButton disabled={selected} variant="text" onClick={onClick}>
      <Box
        sx={{
          height: '20px',
          width: '20px',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: color, // TODO
          fontSize: '12px',
          fontWeight: '700',
          color: 'white',
        }}>
        {title[0]}
      </Box>
      <Box
        sx={{
          fontSize: '13px',
          flex: '1',
          color: '#666',
          marginLeft: '8px',
          marginRight: '8px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}>
        <span>{title}</span>
        {content && <span style={{paddingLeft: '8px', color: '#999'}}>{content}</span>}
      </Box>
      <Box>
        {status !== TraceActivityStatus.PENDING && duration !== undefined && (
          <Box sx={{fontSize: '11px', color: '#999'}}>{duration ? formatDurationFromMillis(duration) : '-'}</Box>
        )}
        {status === TraceActivityStatus.PENDING && <CircularProgress color="primary" size={12} />}
      </Box>
    </ActivityButton>
  );
}
