import {useQuery} from 'react-query';

import {TraceSession} from '@wildix/wim-voicebots-client';

export default function useOngoingTracesQuery() {
  const query = useQuery<{traces: TraceSession[]}>('vb:traces:ongoing', () => ({traces: []}));
  // const client = useQueryClient();

  // setTimeout(() => {
  //   // 'vb:traces:ongoing'
  //   client.setQueryData('vb:traces:ongoing', {
  //     traces: [
  //       {
  //         botId: 'oTURnQcz3338',
  //         botName: 'Test Bot',
  //         callId: '1728563257.1',
  //         callerName: 'Vadim Test CRM',
  //         callerNumber: '111',
  //         company: 'it_w119414',
  //         connectionId: 'fbw5Ge_YliACE6w=',
  //         duration: 29382,
  //         flowIndex: 0,
  //         language: 'en-GB',
  //         sessionId: '1728563257.1.999',
  //         start: 1728563260270,
  //         status: 'pending',
  //       },
  //     ],
  //   });
  // }, 1000);

  return query;
}
